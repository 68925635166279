<template>
<div>
  <div v-bind:style="styles">
    <div id="page-content">
          <!-- <h1 class="hdrtitle" style="margin-top: 4%;">Admissions</h1> -->

          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjethdr dispflex">
                  <div style="width: auto">
                    <!-- <span class="iconsect blubg" style="padding-left: 6px"
                      ><i class="fas fa-users"></i
                    ></span> -->
                    <h1>ADMISSION APPLIED</h1>
                  </div>
                  <!-- <span v-if="onlineAdmissionList.length == 0" class="required">* Note: Please apply for admission in schools to see the data here...</span> -->
                  <div class="mt-2 mr-2">
                  </div>
                </div>
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">S.No.</div> -->
                      <div class="cell">Candidate Name</div>
                      <div class="cell">School Name</div>
                      <div class="cell">Email</div>
                      <div class="cell">Class Applied</div>
                       <div class="cell">Enrollment Status</div>
                       <div class="cell">Assessment</div>
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow" v-for="(data, index) in onlineAdmissionList" :key="index" >
                      <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                      <div class="cell" data-title="Candidate Name">
                        {{ data.firstName + " " + data.lastName }}
                      </div>
                      <div class="cell" data-title="Parent Name">
                        {{ data.school ? data.school.accountName : ''}}
                      </div>
                      <div class="cell" data-title="Email">
                        {{ data.email || '-'}}
                       
                      </div>
                      <div class="cell" data-title="Enrollment Status">
                        {{ data.className }}
                      </div>
                      <div class="cell" data-title="Class Applied">{{ data.admissionStatus }}</div>
                     <div class="cell" data-title="Assessment" >
											<button class="btn" :disabled="data.admissionStatus == 'Active'"><img width="26px" src="../../assets/img/schedule_icon.svg" @click.prevent="openinviatePopUp(index)" alt="Schedule Interview" title="Schedule Interview"></button>
										</div>
                      <div class="cell" data-title="Action">
                       
                         <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px" v-if="data.admissionStatus == 'Approved'">
                            <b-dropdown-item v-if="data.admissionStatus == 'Approved'" @click.prevent="openConfirmPopUp(data._id)">Confirm</b-dropdown-item>
                          </b-dropdown>
                         <button class="btn round orange tooltipt" :disabled="data.admissionStatus != 'Submitted' && data.admissionStatus != 'Need More Info' && data.admissionStatus != 'Documents Missing'" @click.prevent="openEditPopUp(index)">
                            <i class="fas fa-edit"></i
                            ><span class="tooltiptext">Edit</span>
                          </button>
                          <button class="btn round orange tooltipt" @click.prevent="openViewPopUp(index)">
                                   <i class="far fa-eye"></i><span class="tooltiptext">View</span>
                          </button>
                       
                      </div>
                     
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      <b-modal id="addstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
          <div class="contact-form">
            <h2>EDIT CANDIDATE</h2>
            <br />
            <div class="tabs" style="height: 2%;background:none">
               <input
                  type="radio"
                  name="tabs"
                  id="classestab"
                  checked="checked"
                />
                <label for="classestab">Details</label>
                 <div class="tab">
                <div style="height: 350px; overflow-y: auto">
                  <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    :disabled="!isEdit"
                    name="studentFirstName"
                    v-model="studentObj.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                 
                  <label for="stdFirstName" 
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    :disabled="!isEdit"
                    name="studentLastName"
                    v-model="studentObj.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                 
                  <label for="stdLastName" 
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    :disabled="!isEdit"
                    name="stdEmail"
                    v-model="studentObj.email"
                    class="form-control form-input"
                    id="stdEmail"
                    placeholder="Secondary Email"
                  />
                  <label for="stdEmail"
                    >Secondary Email</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                   <select
                     disabled
                      name="status"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="studentObj.admissionStatus"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
<!--                     
                   <input
                    type="text"
                    disabled
                    name="stdEmail"
                    class="form-control form-input"
                    id="stdEmail"
                    v-model="studentObj.admissionStatus"
                    placeholder="Secondary Email"
                  /> -->
                  <label for="enrollmentStatus" 
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                    type="date"
                    :disabled="!isEdit"
                    onkeydown="return false"
                    name="DOB"
                    v-model="studentObj.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                
                  <label for="stdDob" 
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  
                   <input
                   :disabled="!isEdit"
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="studentObj.bloodGroup"
                  />
                 
                  <label for="bloodGroup" 
                    >Blood Group <span class="required">*</span></label
                  >
                </span>
              </div>
             
                  <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input
                           :disabled="!isEdit"
                             name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="studentObj.gender"
                          />
                         
                          <label for="firstName" 
                            >Gender <span class="required">*</span></label
                          >
                        </span>
                      </div>
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <textarea
                             disabled
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            placeholder="Message"
                            v-model="studentObj.comments"
                          ></textarea>
                          
                         
                          <label for="firstName" 
                            >Comments</label
                          >
                        </span>
                   </div>
              <fieldset class="accordion-group-container">
                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    Parent Details
                  </label>
                    <div class="form-row">
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input
                           :disabled="!isEdit"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="studentObj.pointOfContact"
                          />
                          <label for="firstName" 
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="studentObj.pointOfContact == 'Father'">
                          <input
                            type="text"
                            disabled
                            name="fthrname"
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Name"
                          />
                         
                          <label for="fthrnameid" 
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="studentObj.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="Mother"
                            disabled
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother Name"
                          />
                         
                          <label for="fthrnameid" 
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="studentObj.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="Guardian"
                            disabled
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian Name"
                          />
                         
                          <label for="Guardian" 
                            >Guardian Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="studentObj.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid"
                            >Occupation </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            :disabled="!isEdit"
                            v-model="studentObj.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            :disabled="!isEdit"
                            v-model="studentObj.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            disabled
                            name="ptemail"
                            v-model="studentObj.primaryMail"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                          />
                         
                          <label for="ptemailid" 
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            :disabled="!isEdit"
                            v-model="studentObj.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid"
                            >Address Details</label
                          >
                        </span>
                      </div>
                    </div>
                </div>

                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    Candidate Enrollment Details
                  </label>
                  <div class="form-row">
                
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                         <input
                         name="Class"
                         disabled
                          class="form-control form-input"
                          v-model="studentObj.academicYear"
                          type="text"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />
                        <label for="acyearid" 
                          >Academic Year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                         <input
                         name="Class"
                         disabled
                          class="form-control form-input"
                          v-model="studentObj.className"
                          type="text"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />

                         
                        <label for="clname" 
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                    
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">
               
              </div>
              <div class="text-right dk_iconsml">
                
                         <button
                            v-if="!isEdit"
                            type="button"
                            :disabled="studentObj.admissionStatus == 'Documents Missing'"
                            class="btn btnsml edbtn"
                            @click.prevent="editDetails"
                          >
                            <i class="fas fa-edit"></i> <span>Edit</span>
                          </button>
                           <button v-if="isEdit" type="button" @click="updateStudentDetails" class="btn btnsml" id="svbtn">
                                <span>Save</span>
                            </button>
                          <button
                              type="button"
                              class="btn btncl"
                              id="clbtn"
                              @click.prevent="closeUpdatePoup"
                            >
                                
                              <span>Cancel</span>
                            </button>
                          <!-- <div v-if="isEdit">
                           
                            
                          </div> -->
              </div>
            </div>
          </form>
                </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="documenttab"
                />
                <label for="documenttab">Upload Documents</label>
                 <div class="tab">
                    <div class="form-group row" v-for="(data, index) in candidateEditDetails.eligibilityCriteriaDocuments" :key="index">
                          <div class="col-1">
                              <div class="radios">
                                  <div class="row">
                                      <div class="form-check col-3">
                                          <input class="styled-checkbox" id="NIC" type="checkbox" :checked="data.path" disabled>
                                          <label class="abs-position" for="NIC"></label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-4">
                              <label>
                                  <span>{{ data.name }} <span class="required">*</span></span>
                              </label>
                          </div>
                          <div class="col-6">
                            <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;" @click.prevent="preViewDocument(data.path)" v-if="data.path">
                              View
                            </button>
                            <button class="btn btn-doc btn-sm" style="margin-left: 10px;background-color: indianred;" @click.prevent="removeDocument(index,data.key)"   v-if="data.path">
                              Delete
                            </button>
                             <!-- <span v-if="nicDoc._id != null && recordId == null"   style="padding-left: 2%;">
                             {{ `${nicDoc.nicFile.name}` }} /
                             {{`${this.convertKbToMb(nicDoc.nicFile.size)}` + "MB"}}
                           </span> -->
                           
                            <input 
                            v-else
                              type="file"
                              :ref="data.name"
                              :name="data.name"
                              accept="application/pdf, image/jpg, image/png"
                              @change="updateFile(data.apiName,data.name)"/>
                            
                             
                          </div>
                          
                      </div>
                      <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">
                
              </div>
               <div class="dk_icon">
                <span class="required">* Note: Upload all documents and submit</span>
              </div>
              <div class="text-right dk_iconsml">
                
                       
                           <button :disabled="checkPendingUploadDocuments > 0" type="button"  class="btn btnsml" @click="submitDocuments" id="svbtn">
                                <span>Submit</span>
                            </button>
                          <button
                              type="button"
                              class="btn btncl"
                              id="clbtn"
                              @click.prevent="closeUpdatePoup"
                            >
                                
                              <span>Back</span>
                            </button>
                          <!-- <div v-if="isEdit">
                           
                            
                          </div> -->
              </div>
            </div>
                   
               </div>
                </div>
           </div>
      </b-modal>
      <b-modal id="viewAdmissionPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
          <div class="contact-form">
            <h2>View Admission</h2>
            <br />
            <div class="tabs" style="height: 2%;background:none">
               <input
                  type="radio"
                  name="tabs"
                  id="classestab"
                  checked="checked"
                />
                <label for="classestab">Details</label>
                 <div class="tab">
                <div style="height: 350px; overflow-y: auto">
                  <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentFirstName"
                    v-model="studentObj.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                 
                  <label for="stdFirstName" 
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentLastName"
                    v-model="studentObj.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                 
                  <label for="stdLastName" 
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="stdEmail"
                    v-model="studentObj.email"
                    class="form-control form-input"
                    id="stdEmail"
                    placeholder="Secondary Email"
                  />
                  <label for="stdEmail"
                    >Secondary Email</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                   <select
                     disabled
                      name="status"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="studentObj.admissionStatus"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
<!--                     
                   <input
                    type="text"
                    disabled
                    name="stdEmail"
                    class="form-control form-input"
                    id="stdEmail"
                    v-model="studentObj.admissionStatus"
                    placeholder="Secondary Email"
                  /> -->
                  <label for="enrollmentStatus" 
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                    type="date"
                    disabled
                    onkeydown="return false"
                    name="DOB"
                    v-model="studentObj.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                
                  <label for="stdDob" 
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  
                   <input
                   disabled
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="studentObj.bloodGroup"
                  />
                 
                  <label for="bloodGroup" 
                    >Blood Group <span class="required">*</span></label
                  >
                </span>
              </div>
             
                  <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input
                          disabled
                             name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="studentObj.gender"
                          />
                         
                          <label for="firstName" 
                            >Gender <span class="required">*</span></label
                          >
                        </span>
                      </div>
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <textarea
                             disabled
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            placeholder="Message"
                            v-model="studentObj.comments"
                          ></textarea>
                          
                         
                          <label for="firstName" 
                            >Comments</label
                          >
                        </span>
                   </div>
              <fieldset class="accordion-group-container">
                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    Parent Details
                  </label>
                    <div class="form-row">
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input
                           disabled
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="studentObj.pointOfContact"
                          />
                          <label for="firstName" 
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="studentObj.pointOfContact == 'Father'">
                          <input
                            type="text"
                            disabled
                            name="fthrname"
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Name"
                          />
                         
                          <label for="fthrnameid" 
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="studentObj.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="Mother"
                            disabled
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother Name"
                          />
                         
                          <label for="fthrnameid" 
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="studentObj.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="Guardian"
                            disabled
                            v-model="studentObj.parent.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian Name"
                          />
                         
                          <label for="Guardian" 
                            >Guardian Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            disabled
                            v-model="studentObj.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid"
                            >Occupation </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="studentObj.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                           disabled
                            v-model="studentObj.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            disabled
                            name="ptemail"
                            v-model="studentObj.primaryMail"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                          />
                         
                          <label for="ptemailid" 
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            disabled
                            v-model="studentObj.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid"
                            >Address Details</label
                          >
                        </span>
                      </div>
                    </div>
                </div>

                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    Candidate Enrollment Details
                  </label>
                  <div class="form-row">
                
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                         <input
                         name="Class"
                         disabled
                          class="form-control form-input"
                          v-model="studentObj.academicYear"
                          type="text"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />
                        <label for="acyearid" 
                          >Academic Year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                         <input
                         name="Class"
                         disabled
                          class="form-control form-input"
                          v-model="studentObj.className"
                          type="text"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />

                         
                        <label for="clname" 
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                    
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">
               
              </div>
              <div class="text-right dk_iconsml">
                
                         <!-- <button
                            v-if="!isEdit"
                            type="button"
                            class="btn btnsml edbtn"
                            @click.prevent="editDetails"
                          >
                            <i class="fas fa-edit"></i> <span>Edit</span>
                          </button>
                           <button v-if="isEdit" type="button" @click="updateStudentDetails" class="btn btnsml" id="svbtn">
                                <span>Save</span>
                            </button> -->
                          <button
                              type="button"
                              class="btn btncl"
                              id="clbtn"
                              @click.prevent="closeViewPoup"
                            >
                                
                              <span>Cancel</span>
                            </button>
                         
              </div>
            </div>
          </form>
                </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="documenttab"
                />
                <label for="documenttab">Upload Documents</label>
                 <div class="tab">
                    <div class="form-group row" v-for="(data, index) in candidateEditDetails.eligibilityCriteriaDocuments" :key="index">
                          <div class="col-1">
                              <div class="radios">
                                  <div class="row">
                                      <div class="form-check col-3">
                                          <input class="styled-checkbox" id="NIC" type="checkbox" :checked="data.path" disabled>
                                          <label class="abs-position" for="NIC"></label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-4">
                              <label>
                                  <span>{{ data.name }} <span class="required">*</span></span>
                              </label>
                          </div>
                          <div class="col-6">
                            <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;" disabled @click.prevent="preViewDocument(data.path)" v-if="data.path">
                              View
                            </button>
                            <button class="btn btn-doc btn-sm" style="margin-left: 10px;background-color: indianred;" disabled @click.prevent="removeDocument(index,data.key)"   v-if="data.path">
                              Delete
                            </button>
                             <!-- <span v-if="nicDoc._id != null && recordId == null"   style="padding-left: 2%;">
                             {{ `${nicDoc.nicFile.name}` }} /
                             {{`${this.convertKbToMb(nicDoc.nicFile.size)}` + "MB"}}
                           </span> -->
                           
                            <input 
                            v-else 
                            disabled
                              type="file"
                              :ref="data.name"
                              :name="data.name"
                              accept="application/pdf, image/jpg, image/png"
                              @change="updateFile(data.apiName,data.name)"/>
                            
                             
                          </div>
                          
                      </div>
                      <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">
               
              </div>
              <div class="text-right dk_iconsml">
                
                        
                           <!-- <button v-if="isEdit" type="button" @click="updateStudentDetails" class="btn btnsml" id="svbtn">
                                <span>Save</span>
                            </button> -->
                          <button
                              type="button"
                              class="btn btncl"
                              id="clbtn"
                              @click.prevent="closeViewPoup"
                            >
                                
                              <span>Back</span>
                            </button>
                          <!-- <div v-if="isEdit">
                           
                            
                          </div> -->
              </div>
            </div>
                   
               </div>
                </div>
           </div>
      </b-modal>
      
      <b-modal id="inviatePopUp" no-close-on-backdrop
      no-close-on-esc >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>View Schedule Interview</h2>
        <br />
       <div class="row">				
				<div class="col-lg-12">
					<div class="widjet">
						<div class="widjethdr dispflex">
							<div style="width:auto">
								<span class="iconsect blubg" style="padding-left:11px;"><i class="fas fa-calendar-plus"></i></span>
								<h1>View a Invite</h1>
							</div>
						</div>	
						<div class="p-3 widjetcontent" v-if="studentObj.schedule">
							<div style="padding:10px;width:100%;background:#f1f1f1">
								<label class="custradio" style="margin-bottom:0">Online
								  <input type="radio"  
                    name="schedule" 
                    disabled
                    v-model="offline"
                    value="Online" 
                    >
								  <span class="checkmark"></span>
								</label>
								<label class="custradio" style="margin-bottom:0">Offline
								  <input 
                  type="radio"
                  disabled
                  name="schedule" 
                  v-model="offline"
                  value="Offline" >
								  <span class="checkmark"></span>
								</label>
							</div>
							<div class="row" v-if="offline == 'Online'">
								<div class="col-md-2 p5">
                  Meeting Link
								
								</div>
								<div class="col-md-6 p5">
									<div style="padding:10px;width:100%;background:#f1f1f1" class=""> 
                    <a :href="studentObj.schedule.link" target="_blank">{{ studentObj.schedule.link }}</a>
                    <!-- <input type="url" disabled v-model="studentObj.schedule.link" name="totseatsEdit"  class="form-control form-input" id="totseatsid"  > -->
									</div>
								</div>
								
							</div>
              <div class="row" v-else>
								 <div class="form-group input-group mt-md-3" style="padding-right: 1%;padding-left: 1%;">
                            <span class="web-has-float-label">
                              <textarea
                                class="form-control web-form-input"
                                style="min-height:135px;resize:none;background-color: #eeedeb;"
                                id="message"
                                disabled
                                placeholder="Message"
                                v-model="studentObj.schedule.message"
                              ></textarea>
                             
                              <label for="message" 
                                >Message </label
                              >
                            </span>
                          </div>
								
								
							</div>
						</div>
						
					</div>
				</div>
			</div>
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
         
          <button @click.prevent="closeInvitePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Close</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
     <b-modal id="confirmPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
      <div class="contact-form">

        <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Confirmation</h2>
        <br />
        <!-- I here by acknowledge that I am going to join my kid in your school. -->
        Confirm your admission for your kid
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
           @click.prevent="confirmationForAdmission"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeConfirmPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
        
      </div>
    </b-modal>
     
  </div>
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import VueMonthlyPicker from "vue-monthly-picker";

export default {
  name: "candidates",
  data() {
    return {
      searchWords: '',
      skip: 0, // set the skip info
      limit: 25, // set the limit info
      onlineAdmissionList: [],
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      isEdit: false,
      countAll: true,
      totalCount: 0,
      admissionId: null,
      candidateEditDetails: {},
      studentObj: {},
       statusList: [
        'Submitted',
        'Active',
        'Enrolled',
        'Need More Info',
        'Documents Missing',
        'Approved',
        'Rejected'
      ]
    };
  },
  created() {
    this.getOnlineAdmissionList();
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                  this.getOnlineAdmissionList();
              }
          }
  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    },
     checkPendingUploadDocuments(){
       if(this.candidateEditDetails  && this.candidateEditDetails.eligibilityCriteriaDocuments && this.candidateEditDetails.eligibilityCriteriaDocuments.length > 0){
        
       const checkpendings = this.candidateEditDetails.eligibilityCriteriaDocuments.filter( x => x.path == '')
       return checkpendings.length
      }else{
        return 0
      }
    
    },
   
  },
  methods: {
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
   
    preViewDocument(path) {
      window.open(path, "_blank");
    },
    async openConfirmPopUp(id){
     this.admissionId = id
     this.$bvModal.show("confirmPopUp");
    },
    async submitDocuments(){
      let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(
            `/admission/submitAllDocuments/${this.candidateEditDetails._id}`,
            this.candidateEditDetails,
            userData.token
          );

          if (response.isSuccess) {
            this.closeUpdatePoup()
            this.getOnlineAdmissionList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
    },
    
     async confirmationForAdmission(){
      let obj = {
        admissionStatus : 'Confirm'
      }
      
      let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(
            `/admission/updateOnlineAdmission/${this.admissionId}`,
            obj,
            userData.token
          );

          if (response.isSuccess) {
             this.getOnlineAdmissionList();
             this.closeConfirmPopUp()
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
    },
    closeConfirmPopUp(){
       this.admissionId = null
       this.$bvModal.hide("confirmPopUp");
    },
    closeInvitePopUp(){
       this.$bvModal.hide("inviatePopUp");
    },
     async removeDocument(index,key){
        let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
         let newList = []

         this.candidateEditDetails.eligibilityCriteriaDocuments.forEach((x, i) => {
         if(i == index){
           x.key = '',
           x.path = ''
         }
         newList.push(x)
				});

        const response = await ViService.viXPut(
          `/document/removeOnlineAdmissionDocument/${this.candidateEditDetails._id}/${key}`,
          newList,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
         this.candidateEditDetails.eligibilityCriteriaDocuments = newList
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      
      
     
    },
    async updateStudentDetails(){

      let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(
            `/admission/updateParentPortalOnlineAdmission/${this.studentObj._id}`,
            this.studentObj,
            userData.token
          );

          if (response.isSuccess) {
            this.closeUpdatePoup()
            this.getOnlineAdmissionList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
    },
     async updateFile(name,docType) {

       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType][0].files[0]
        const fd = new FormData();
        fd.append('doc', docFileDetails, docFileDetails.name,docFileDetails.actType);
        const response = await ViService.viPutOnly(`/document/parentPortal/uploadFile/${this.candidateEditDetails._id}/${name}`,fd,userData.token);
        
       if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.candidateEditDetails.eligibilityCriteriaDocuments = response.data
           this.getOnlineAdmissionList();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }

    },
    editDetails() {
      this.isEdit = true;
    },
    openEditPopUp(index){
     this.isEdit = false;
     this.studentObj = {}
     const studentDetails = this.onlineAdmissionList[index];

     const cloneStudentDetails = { ...studentDetails }
     this.studentObj = cloneStudentDetails
     this.studentObj.comments = this.studentObj.comments || ''

     this.studentObj.occupation = this.studentObj.parent.occupation || ''
     this.studentObj.religion = this.studentObj.parent.religion || ''
     this.studentObj.nationality = this.studentObj.parent.nationality || ''
     this.studentObj.address = this.studentObj.parent.address || ''
     this.studentObj.primaryMail = this.studentObj.parent.email || ''

    if(this.studentObj.dob){
        this.studentObj.dob = this.studentObj.dob.substring(0,10);
      }
    if(this.studentObj.doj){
      this.studentObj.doj = this.studentObj.doj.substring(0,10);
    }
    if(this.studentObj.dateOfLeaving){
      this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(0,10);
    }
    this.studentObj.academicYear = cloneStudentDetails.academicYear.from + ' - ' + cloneStudentDetails.academicYear.to

    this.candidateEditDetails = studentDetails
    this.$bvModal.show("addstudpop");

    },
    openViewPopUp(index){
     this.studentObj = {}
     const studentDetails = this.onlineAdmissionList[index];

     const cloneStudentDetails = { ...studentDetails }
     this.studentObj = cloneStudentDetails
     this.studentObj.comments = this.studentObj.comments || ''

     this.studentObj.occupation = this.studentObj.parent.occupation || ''
     this.studentObj.religion = this.studentObj.parent.religion || ''
     this.studentObj.nationality = this.studentObj.parent.nationality || ''
     this.studentObj.address = this.studentObj.parent.address || ''
     this.studentObj.primaryMail = this.studentObj.parent.email || ''

    if(this.studentObj.dob){
        this.studentObj.dob = this.studentObj.dob.substring(0,10);
      }
    if(this.studentObj.doj){
      this.studentObj.doj = this.studentObj.doj.substring(0,10);
    }
    if(this.studentObj.dateOfLeaving){
      this.studentObj.dateOfLeaving = this.studentObj.dateOfLeaving.substring(0,10);
    }
    this.studentObj.academicYear = cloneStudentDetails.academicYear.from + ' - ' + cloneStudentDetails.academicYear.to

    this.candidateEditDetails = studentDetails
    this.$bvModal.show("viewAdmissionPopUp");

     
    },
    closeUpdatePoup(){
    this.isEdit = false;
    this.$bvModal.hide("addstudpop");
    },
    closeViewPoup(){
    this.$bvModal.hide("viewAdmissionPopUp");
    },
     openinviatePopUp(index){

     const details = this.onlineAdmissionList[index]

     const studentDetails = this.onlineAdmissionList[index];
     const cloneStudentDetails = { ...studentDetails }

     this.studentObj = cloneStudentDetails
     
     this.studentObj.schedule = this.studentObj.schedule || { type: 'Online',link: '',message: ''}

     this.offline = this.studentObj.schedule.type

     this.$bvModal.show("inviatePopUp");

    },
    async getOnlineAdmissionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/admission/getParentOnlineAdmissionListView?parentId=${userData.userDetails._id}`,
          userData.token
        );
        if (response.isSuccess) {
          this.onlineAdmissionList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
  },
};
</script>